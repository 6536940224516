.timeline-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    width: 850px;
    border-radius: 6px;    
}
.timeline-content-validate{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border-radius: 6px;    
    
}

.timeline-columm{
    display: flex;
    flex-direction: column;
    width: 850px;
    height: auto;
    min-width: 290px;
    padding-bottom: 0px;
    align-items: flex-start;
    flex-shrink: 0;
}   

.timeline-itens{
    display: flex;
    flex-direction: row;
    background-color: #FFF;
    width: 850px;
    border-radius: 8px;
    box-shadow: 1px 3px 8px rgba(0,0,0, 0.2);
    margin-bottom: 14px;
    padding: 14px 14px 0px 14px;
}

.img-product img{
    width: 200px;
    height: 200px;

}

.img-product{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
}

.info-product{
    width: 630px;
    height: 263.75px;
}

.product-name, .dresciption{
    color: #595959;
    font-family: Arial;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.2px; /* 120% */
}
.price{
    color: #948E91;
    font-family: Century Gothic;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 67.2px; /* 140% */
}
.coments{
    color: #1796D0;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    padding: 6px 19.71px 6px 11px;
    border: none;
    background-color: transparent;
    transition: transform 0.5s; 
}

.coments:hover{
    transform:  scale(1.2);
}
.admin-actions button {
    padding: 10px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .admin-actions button:first-child {
    background-color: green;
  }
  
  .admin-actions button:nth-child(2){
    background-color: red;
  }
  .admin-actions button:last-child {
    background-color: blue;
  }
.ir-loja{
    display: flex;
    padding: 6px 19.71px 6px 11px;
    align-items: flex-start;
    gap: 5.68px;
    border-radius: 8px;
    border: 1px solid #CECECE;
    text-decoration: none !important;
    color: deepskyblue;
    cursor: pointer;
    transition: transform 0.5s; 
}

.ir-loja:hover{
    background-color: rgba(255,255,255, 0.2);
    box-shadow: 1px 3px 8px rgba(0,0,0, 0.2);
    border-radius: 10px;
    transform:  scale(1.2);
}

.interaction{
    display: flex;
    justify-content: space-between;
}
.collapsed{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start !important;
    color: #948E91;
    max-width:max-content; 
}

    
.material-symbols-outlined {
    
border: none;
background: transparent;  
}
.login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: auto;
    padding: 20px;
    position: absolute;
    bottom: 46%;
    left: 39%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .modal-comments{
    max-width: 51.25rem;
    background: #FFF;

  }
  .login-form button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
  }
  .product-reject{
    width: 240px;
    height: 39px;
    text-align: center;
    background: #A9A9A9;
    justify-content: center;
    display: flex;
    padding: 8px;
    border-radius: 7px;
    color: #000;
    font-weight: bold;
  }
  .edit{

    display: flex;
    justify-content: center;
  }
  .input-edit{

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 800px;
    align-items: center;
    position: relative;
    top: 100px;
   
  }
  .input-edit input{
    padding: 8px 8px 8px 8px;
    margin: 8px 8px 8px 8px;
    width: 240px;
    height: 35px;
  }

  .input-edit button{
    border: none;
    border-radius: 10px;
    width: 140px;
    height: 30;
    background-color:  #FFA41C;
  color: #000;
  }
  .load-more-button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #5FBDFF;
    display: flex;
    font-size: 26px;
  }

  
  .share-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .share-balloon {
    background: #f9f9f9;
  }
  .pdp-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .timline-pdp-container{
    top: 28% !important;
    display: flex !important;
    position: absolute !important;
  }
  .coupon-info {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .coupon-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .coupon-description {
    margin-top: 0.5rem;
  }
  
  .coupon-expiration {
    font-size: 0.875rem;
    color: #757575;
  }
  
  .coupon-code {
    font-size: 1rem;
    font-weight: bold;
    background-color: #efefef;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
  }
  .content-coupom{
    margin-bottom: 10px !important;
   
  }