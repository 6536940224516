.container {
    max-width: 800px;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  p, li {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  .logo {
    display: block;
    height: auto;
    margin: 0 auto;
}
.central-ajuda {
    width: 60%; /* Reduzir a largura para centralizar mais o conteúdo */
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    
}
.div-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}
.div-btn button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(255,164,28,1) 0%, rgba(255,184,58,1) 100%);
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
    font-weight: bold;
  }
  .div-btn button:hover {
    background: linear-gradient(90deg, rgba(255,184,58,1) 0%, rgba(255,164,28,1) 100%);
  }
  @media (max-width: 700px){
    .central-ajuda{
        width: 100% !important; 
    }
    .div-btn button{
        font-size: 36px;
    }
    .footer p{
        font-size: 26px;
    }
    .container p{
      font-size: 46px !important;
    }
    .container li{
      font-size: 46px !important;
    }
    .container h2{
      font-size: 46px !important;
    }
  }