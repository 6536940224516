.info-promo{
    display: flex;
    justify-content: center;
}

.btn-promo{
    margin-top: 0.625rem;
    align-items: center;
}
.btn-promo button{
    background: #FFA41C;
    border: none;
    border-radius: 0.875rem;
    height: 2.8125rem;
    width: 100%;
    color: #181818 !important;
}
.text-promo{
    display: flex;
    flex-direction: column;
    text-align: center;

}
.text-promo textarea{
   padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    height: 12.5rem;
}
.watch-out{
    font-size: 12px;
    color: #181818;
    font-weight: 900;

}
.watch-out b{
    color: #FFA41C;
}
label{
    font-weight: bold;
}
.select-tag {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
  }
  
  .select-tag:focus {
    border-color: #ffc107;
    outline: none;
  }
  
  .select-tag option {
    padding: 10px;
  }
  

@media only screen and (max-width: 48rem) {
    .btn-promo button{
        height: 2rem;
        border-radius: 0.5rem;
    }
    
    .text-promo textarea{
        padding: 0.25rem 0.25rem 0.25rem 0.25rem;
        height: 10rem;
    }
}
