.container {
    width: 80%;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .content {
    margin: 20px 0;
  }
  .logo {
    display: block;
    height: auto;
    margin: 0 auto;
}

.sobre-nos {
    width: 60%; /* Reduzir a largura para centralizar mais o conteúdo */
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}
.div-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}
.div-btn button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(255,164,28,1) 0%, rgba(255,184,58,1) 100%);
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
    font-weight: bold;
  }
  .div-btn button:hover {
    background: linear-gradient(90deg, rgba(255,184,58,1) 0%, rgba(255,164,28,1) 100%);
  }
  @media (max-width: 700px){
    .central-ajuda{
        width: 100% !important; 
    }
    .div-btn button{
        font-size: 36px;
    }
    .footer p{
        font-size: 26px;
    }
    .content p{
      font-size: 46px !important;
    }
  }