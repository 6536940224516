.btn-search{
    background-color: transparent;
    border: none;
}

.input-bar input{
    font-family: "DM Sans", Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
    width: 100%;
    height: 100%;
    caret-color: rgb(255,164,28);
    border: none;
    background: transparent;
    cursor: text;
    outline: none;

}

.search-bar{
    display: flex;
}
.input-bar{
    border: 0.5px solid #d3d3d3;
    width: 440px;
    height: 40px;
    border-radius: 24px;
    margin-top: 10px;
}
.product-preview {
    position: relative;
    display: inline-block;
    bottom: 1rem;
  }
  
  /* Conteúdo do dropdown */
  .product-preview .dropdown-content {
    position: absolute;
    background-color: #ffffff;
    min-width: 28rem;
    box-shadow: 0px 0.5rem 1rem 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 2.5rem;
    max-height: 800px;
    overflow: auto;
  }
  
  /* Mostra o dropdown quando o mouse passa por cima */
  .product-preview:hover .dropdown-content {
    display: block;
  }
  
  /* Estilo para cada item do dropdown */
  .dropdown-content p {
    color: black;
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: block;
  }
  

  .product-name a {
    color: #333; 
    text-decoration: none; 
    font-weight: bold; 
    transition: color 0.3s ease; 
    font-size: 1rem;
  }
  
  .product-name a:hover {
    color: #FFA41C; 
  }
 .catalog-link {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #FFA41C;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}
 .dropdown-content .price{
  font-size: 2.25rem;
 }
  @media only screen and (max-width: 37.5rem) {
    .product-preview .dropdown-content {
      min-width: 7.5rem;
    }
  
    .dropdown-content p {
      padding: 0.5rem 0.75rem; 
    }
  }