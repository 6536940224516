
    div.footer{
        height: 150px;
    }
    div.mainFooter{
        height: 50px;
        
        position: relative;
        top: 50px;
    }
    .copy-right{
    justify-content: center;
    margin: auto;
    display: flex;
    
    }
    
    .copy-right .left{
    display: flex;
    align-self: center;
    max-width: 174px;
    
    }
    
    .copy-right .right{
    display: flex;
    text-align: right;
    
    }
    .termos-foote{
    max-width: 400px !important;
    display: flex !important;
    align-items: center!important;
    width: 100%;
    }
    .copy-right .right .fab{
    margin: 0px 10px;
    border-radius: 50%;
    cursor: pointer;
    color: #313639;
    padding: 15px;
    border: 2px solid #c7c5c5;
    }
    .fa-facebook-f:before{
    margin-right: 2px;
    }
    .fab {
    color: #ffffff; /* Cor do ícone */
    border-radius: 50%; /* Arredonda as bordas para formar um círculo */
    padding: 10px; /* Espaçamento interno para formar um círculo maior */
    transition: background-color 0.3s, color 0.3s; /* Suaviza a transição de cor e fundo */
    }
    
    .fab.fa-facebook-f:hover {
    background: linear-gradient(45deg, #3b5998, #3b5998); 
    border-color: #3b5998 !important;
    }
    
    
    .fab.fa-twitter:hover {
    background: linear-gradient(45deg, #1da1f2, #0096D6, #1da1f2); /* Gradiente azul do Twitter */
    border-color: #1da1f2 !important;
    
    }
    
    .fab.fa-instagram:hover {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
    border-color: #fd5949 !important;
    }
    .redes{
    text-align: center;
    }
    