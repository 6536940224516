.menu{
  color: #181818;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
}
.nav {
  display: flex;
  justify-content: center;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 1.25rem; /* assuming the base font-size to be 16px */
}

.nav-item a {
  text-decoration: none;
  color:  #181818 !important;
}
.nav-item button {
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-weight: bold;
  color:  #181818 !important;
}
.nav-item button:hover{
  text-decoration: underline;
}
.nav-item a:hover {
  text-decoration: underline;
}

.search-icon{
cursor: pointer;
transition: transform 0.5s;  
}

.search-icon:hover{
background-color: rgba(255,255,255, 0.2);
box-shadow: 0.0625rem 0.1875rem 0.5rem rgba(0,0,0, 0.2);
border-radius: 1.5625rem; /* assuming the base font-size to be 16px */
transform: scale(1.2);
}


.notification{
cursor: pointer;
transition: transform 0.5s;  
}

.notification:hover{
background-color: rgba(255,255,255, 0.2);
box-shadow: 0.0625rem 0.1875rem 0.5rem rgba(0,0,0, 0.2);
border-radius: 1.5625rem; /* assuming the base font-size to be 16px */
transform: scale(1.2);
}

.selfmenu{
cursor:pointer;transition:
transform .5s;}
.selfmenu:hover{background-color:
rgba(255,255,255,.2);border-radius:
1.5625em;transform:
scale(1.2);}
.image-btn{
  cursor:pointer;transition:
  transform .5s;}
  .image-postar:hover{background-color:
  rgba(255,255,255,.2);border-radius:
  1.5625em;transform:
  scale(1.2);}
  

.menuSpace{width:
27.5em;height:
2.5em;}

.container-menu-bar{display:flex;}

.input-bar{background-color:
rgba(255,255,255,.2);box-shadow:
.0625em .1875em .5em
rgba(0,0,0,.2);animation:
flipSearchBar
1.5s;}
.category-navigation {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  overflow-x: hidden;
}

.category-button {
  background: none;
  border: 1px solid #ffc107;
  border-radius: 4px;
  margin-right: 5px ;
  height: 35px;
  cursor: pointer;
  transition: color 0.3s ease;
  font-weight: bold;
  text-transform: capitalize;
}
.category-button:hover{
  background-color: #ffc107;
  color: #FFF;
  border: 1px solid #000;
}
.category-button.selected {
  color: #ffc107;
}
.category-dropdown {
  width: 1px;
  height: 10px;
}
.btn-nextandprev {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 16px;
  border-radius: 50%;
  background-color: #ffc107;
  font-weight: bolder;
  color: black;
  font-size: 22px;
  text-align: center;
}
.category-carousel {
  display: flex;
  overflow: hidden;
  transition: transform 0.5s ease; /* Adicione um atraso de 0.5 segundos (ajuste conforme necessário) */
}
.category-slider {
  display: flex;
  transition: transform 0.3s ease-in-out;
}
.btn-nextandprev:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
option {
  background: #f8f8f8;
  color: #333;
}
.notification-alert {
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 16px;
  border-radius: 50%;
  background-color: #ffc107;
  color: white;
  font-size: 18px;
  text-align: center;
  margin-left: 5px;
  padding-top: 6px;
}
.menu .nav .nav-list .nav-item .nav-item {
  font-size: 40px;
}
.menu .nav .nav-list .btn-coupom{
  font-size: 34px !important;
  padding-top: 8px !important;
}
@keyframes flipSearchBar{from{transform:rotatey(90deg);}to{transform:rotatey(0deg);}}

.input-bar input{background-color:
transparent;border:
0;font-size
:1.25em;color
:#333333;outline
:none;margin-right
:.5em;cursor
:pointer;}

.input-bar input::placeholder{color:
#7e7878bd;}

.btn-search{background-color:
transparent;border:
0;display:flex;justify-content:center;align-items:center;}

@media only screen and (max-width:48em){
  .menu{
      font-size: 1rem;
  }
  .nav-list {
      flex-direction: column;
      gap: 1rem;
  }
  .menuSpace{
      width: 27.5rem;
      height: 2rem;
  }
  .input-bar input{
      font-size: 1rem;
  }
}
@media (min-width: 700px){
  .input-bar{
    height: 60px !important;
  }
}