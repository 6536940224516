/* *{
    font-family: sans-serif;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

body{
    background-color: #18181B;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logo{
    max-width: 600px;
    margin-bottom: 24px;
}

.container-feed{
    max-width: 480px;
    margin: 14px 0;
}

.container-feed span{
    color: #FFF;
    font-size: 22px;
}

.slider{
    -webkit-appearance: none;
    width: 100%;
    border-radius: 5px;
    background: #DFDFDF;
    height: 18px;
    outline: none;
    margin-top: 8px;
}

.button-cta{
    height: 40px;
    background-color: #3EB72B;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
}

.container-password{
    max-width: 480px;
    margin: 14px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.title{
    text-align: center;
    color: #FFF;
    font-size: 28px;
    margin-top: 24px;
    margin-bottom: 8px;
}

.success-message{
    text-align: center;
    color: #FFF;
    font-size: 28px;
    margin-top: 24px;
    margin-bottom: 8px;  
    visibility: hidden; 
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.password{
    height: 60px;
    background-color: rgba(0,0,0, 0.40);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    border: 1px solid #313131;
    transition: transform 0.5s;
}

.password:hover{
    transform: scale(1.05);
}

.tooltip{
    color: #FFF;
    position: relative;
    top: 20px;
    padding: 6px 8px;
    background: rgb(15,15,15);
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.hide{
    display: none;
}

.container-password:hover .tooltip{
    visibility: visible;
    bottom: 50px;
    opacity: 1;
}

.container-password:active .success-message{
    visibility: visible;
    bottom: 50px;
    opacity: 1;
    transition: transform 2s;
} */

/*
body{
    background-color: #121212;
    color: #ffffff;
}
*/





.cabecalho {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
}


.search-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-input {
    width: 0;
    height: 30px;
    transition: width 0.5s ease-in-out;
}

.search-button {
    cursor: pointer;
    margin: 5px 5px;
}


.logo {
    /* Seu CSS para a logo vai aqui */
    max-width: 150px;
    margin-bottom: 24px;
}

#abrir-modal {
    /* Seu CSS para o botão vai aqui */
    padding: 10px 20px;
    background-color: #FFA41C;
    text-decoration: none;
    max-height: 50px;
    border-radius: 5px; 
    margin: 10px 10px;
}


.catalogo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.produto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.produto img {
    max-width: 200px;
    height: 260px;
    object-fit: contain;
    margin-bottom: 10px;
}

.produto h2 {
    margin-bottom: auto;
}

.produto p {
    align-self: flex-start;
}

.produto a {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #FFA41C;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.catalogo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    max-width: 850px;
    margin: 0 auto;
}

.paginacao {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.paginacao a.action {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #FFA41C;
    text-decoration: none;
    border-radius: 5px;
    color: black;
}


.numero-pagina {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 2px solid #ccc;
    margin: 0 5px;
}

.atual {
    font-weight: bold;
    font-size: 1.2em;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .paginacao ul li.page{
    padding: 10px 20px;
    margin-left: 5px; 
    background-color: #ccc;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .active {
    font-weight: bold;
    font-size: 1.2em;
    background-color: #FFA41C !important;
  }

.modal-conteudo {
    background-color: #fefefe;
    margin: 15% auto; /* Centraliza o modal na tela */
    padding: 20px 20px;
    border: 1px solid #888;
    width: 450px; /* Largura do modal */
    height: 400px; /* Altura do modal */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-conteudo h2 {
    text-align: center;

}

#form-produto {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#form-produto input[type="text"] {
    margin-bottom: 10px;
    height: 30px;
    border-radius: 5px;
}

.btnModal {
    display: flex;
    justify-content: space-between;
    
}

#form-produto button {
    width: 100px;
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #FFA41C;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

