.termos-de-uso {
    width: 60%; /* Reduzir a largura para centralizar mais o conteúdo */
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.termos-de-uso h2 {
    color: #333;
    margin-bottom: 20px; /* Adicionar espaço abaixo do título */ 
    font-weight: 900;
}

.termos-de-uso ol {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 700;
}

.termos-de-uso li {
    margin-bottom: 20px; /* Adicionar espaço entre os termos */
    font-weight: 500;
    font-size: 20px;

}

.logo {
    display: block;
    height: auto;
    margin: 0 auto;
}
.div-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}
.div-btn button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(255,164,28,1) 0%, rgba(255,184,58,1) 100%);
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
    font-weight: bold;
  }
  .div-btn button:hover {
    background: linear-gradient(90deg, rgba(255,184,58,1) 0%, rgba(255,164,28,1) 100%);
  }

  @media (max-width: 700px){
    .termos-de-uso li {
        font-size: 46px;
        
        ;
    }
    .termos-de-uso{
        width: 100% !important; 
    }
    .div-btn button{
        font-size: 36px;
    }
    .footer p{
        font-size: 26px;
    }
  }