.comment-container {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.comment-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.comment-username {
  font-weight: bold;
  color: #333;
}

.comment-text {
  margin: 10px 0;
  color: #333;
}

.comment-like-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #010407;
}

.comment-delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #dc3545;
}

.comment-replies {
  margin-left: 20px;
}

.comment-reply-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #000000;
}

.comment-reply-area {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.comment-post-reply-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #5FBDFF;
  display: flex;
  width: 40px;
  justify-content: space-evenly
}

.resp-test {
  display: flex;
  flex-direction: column;
  background-color: #7D7C7C;
  border-radius: 0.25rem;
}

.info-comments {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.user-name-content {
  display: flex;
  justify-content: flex-end;
}

.resp-test p, .resp-test b {
  margin-left: 4px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  color: #333;
}
textarea{
  min-height: 150px;
  width: 100%
}
@media (max-width: 600px) {
  .comment-container {
    padding: 5px;
  }

  .comment-user-image {
    width: 30px;
    height: 30px;
  }

  .comment-username {
    font-size: 0.8em;
  }

  .comment-text {
    font-size: 0.8em;
  }

  .comment-like-button,
  .comment-delete-button,
  .comment-reply-button,
  .comment-post-reply-button {
    font-size: 0.8em;
  }

  .resp-test p, .resp-test b {
    font-size: 0.8em;
  }
}
