
.register-container{
  background-color: white;
}
.register {
  width: 500px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.register h1 {
  text-align: center;
  margin-bottom: 20px;
}
.register label{
  display: flex;
  justify-content: space-between;
  width: 151px;

}
.register label a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.register input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.register .error-message {
  color: red;
  margin-bottom: 10px;
}

.register .password,
.register .others {
  display: flex;
  justify-content: space-between;
}

.register .password input,
.register .others input {
  width: 48%;
}

.register .terms {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.register .terms label input {
  margin: auto;
  width: auto;
}
.register .btn-register {
  text-align: center;
}

.register .btn-register button {
  padding: 10px 20px;
  border: none;
  background: linear-gradient(90deg, rgba(255,164,28,1) 0%, rgba(255,184,58,1) 100%);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register .btn-register button:hover {
  background: linear-gradient(90deg, rgba(255,184,58,1) 0%, rgba(255,164,28,1) 100%);
}
.register-mobile input{
  font-size: 30px !important;
}
.register-mobile{
  width: 90%;
}