

*{
  font-family: 'Lato', sans-serif;
}
body {
    margin: 0; 
    background-color: #fff;
  }
  
  .container-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
  
  .timeline {
    height: 100%; 
    overflow-y: auto;
    padding-top: 18.875rem;
    background-color: #fff; 
    display: flex;
    justify-content: center;}
    
    .container-header-mobile{
      position: sticky;
      top: 0;
      bottom:0;
      height: 100%;
      width: 100%;
      justify-content: center;
      display: flex;
  }
