.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 2%!important;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: transform .2s;
}

.user-profile:hover {
  transform: scale(1.02);
}

.image-upload {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  margin-left: 62px;
}

.image-upload img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffc107;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 18px;
}

.image-upload:hover .overlay {
  opacity: 1;
}

.user-profile h2, .user-profile p {
  margin: 5px 0;
  color: #343a40;
  text-align: center;
}

.user-profile button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(255,164,28,1) 0%, rgba(255,184,58,1) 100%);
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
  font-weight: bold;
}

.hasImage{
  display: block !important;
}

.user-profile button:hover {
  background: linear-gradient(90deg, rgba(255,184,58,1) 0%, rgba(255,164,28,1) 100%);
}
.logo{
  width: 7.375rem;
height: 4.2895625rem;
}
/* myprofileMobile.css */
.user-profile-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;  
  margin-top: 5%!important;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: transform .2s;
}

.user-profile-mobile:hover {
  transform: scale(1.03);
}

.user-profile-mobile .image-upload {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.user-profile-mobile .image-upload img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffc107;
  transition: transform .2s;
}

.user-profile-mobile .image-upload:hover img {
  transform: scale(1.1);
}

.user-profile-mobile .file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.user-profile-mobile .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 12px;
}

.user-profile-mobile .image-upload:hover .overlay {
  opacity: 1;
}

.user-profile-mobile h2, .user-profile-mobile p {
  margin: 2px 0;
  color: #343a40;
  text-align: flex-start;
  font-size: 36px;
  padding: 10px;
  font-weight: bolder;
}

.user-profile-mobile button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(255,164,28,1) 0%, rgba(255,184,58,1) 100%);
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
  font-weight: bold;
  font-size: 16px;
}

.user-profile-mobile .hasImage{
  display: block !important;
}

.user-profile-mobile button:hover {
  background: linear-gradient(90deg, rgba(255,184,58,1) 0%, rgba(255,164,28,1) 100%);
}

.user-profile-mobile .logo{
  width: 3.6875rem;
  height: 2.14478125rem;
  transition: transform .2s;
}

.user-profile-mobile .logo:hover {
  transform: rotate(360deg);
}
.perfil-mobile{
  height: 800px;
  padding: 20px;
}
.perfil-mobile .image-upload{
  width: 50%;
  height: 50%;
}
.perfil-mobile .progress-bar{
  height: 35px !important;
  font-size: 26px;
}
.perfil-mobile .btn-voltar button{
  font-size: 32px !important;
}
.Toastify__toast-body button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(255,164,28,1) 0%, rgba(255,184,58,1) 100%);
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
  font-weight: bold;
}
.Toastify__toast-container--top-center{
  top: 24em !important;
}